import React, { useState, useEffect, Fragment } from "react";
import emailjs from "emailjs-com";
import "./Advice.css";



const Advice = (props) => {
  const { userData, userActive } = props;
  // useState
  const [adviceName, setAdviceName] = useState("");
  const [adviceEmail, setAdviceEmail] = useState("");
  const [adviceSubject, setAdviceSubject] = useState("");
  const [adviceMessage, setAdviceMessage] = useState("");
  const [subtmitAdviceDay, setSubmiAdvicetDay] = useState(false);
  // Función para detecar el día
  useEffect(() => {
    const date = new Date();
    const day = date.getDay();
    if (day === 4) {
      setSubmiAdvicetDay(true);
    } else {
      setSubmiAdvicetDay(false);
    }
  }, []);

  // Función para enviar la asesoría
  const sendAdvice = (e) => {
    e.preventDefault();
    if (!adviceSubject.trim() || !adviceMessage.trim()) {
  
    } else {
      emailjs
        .sendForm(
          "administraciónCEHF",
          `${userData.idEmailAsesoria}`,
          e.target,
          "user_VGe2axNgQKXlEzuYyPuBl"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
      setAdviceSubject("");
      setAdviceMessage("");
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {subtmitAdviceDay ? (
            <Fragment>
              <form onSubmit={sendAdvice}>
                <div className="form-group">
                  <h2>Agenda tu asesoría</h2>
                  <label>Nombre del alumno</label>
                  <input
                    className="form-control mb-4"
                    type="text"
                    name="name"
                    placeholder="Nombre y apellidos"
                    value={`${userData.nombre} ${userData.primerApellido} ${userData.segundoApellido}`}
                    onChange={(event) => setAdviceName(event.target.value)}
                  />
                  <label>Correo del alumno</label>
                  <input
                    className="form-control mb-4"
                    type="email"
                    name="email"
                    placeholder="Ejemplo: alumno@cehf.com"
                    value={userActive.email}
                    onChange={(event) => setAdviceEmail(event.target.value)}
                  />
                  <label>Ingresa el asunto</label>
                  <input
                    className="form-control mb-4"
                    type="text"
                    name="subject"
                    placeholder="Ejemplo: Asesoría Matemáticas 3ro Primaria"
                    value={adviceSubject}
                    onChange={(event) => setAdviceSubject(event.target.value)}
                  />
                  <label>Agrega un mensaje</label>
                  <textarea
                    className="form-control"
                    name="mensaje"
                    placeholder="Mensaje"
                    rows="10"
                    value={adviceMessage}
                    onChange={(event) => setAdviceMessage(event.target.value)}
                  />
                  <input
                    className="advice-button mt-4"
                    type="submit"
                    value="Enviar"
                  />
                </div>
              </form>
            </Fragment>
          ) : (
            <div className="notSubmitDay">
              <h4>Sólo podrás agendar asesorías los días Jueves</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Advice;
