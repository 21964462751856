import React, { useState, useEffect } from "react";
import "./Calendar.css";

const Calendar = () => {
  // useState
  const [calendarioImg, setCalendarioImg] = useState([]);
  // useEffect

  useEffect(() => {
    const getCalendars = async () => {
      try {
        const data = await fetch("https://cehf-app.herokuapp.com/calendarios");
        const res = await data.json();
        setCalendarioImg(res);
      } catch (error) {
        console.log(error);
      }
    };

    getCalendars();
  }, []);
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center margin-top">Próximas Actividades</h2>
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            {calendarioImg.map((calendario) => {
              return (
                <div class="carousel-inner">
                  <div class="carousel-item  active">
                    <img
                      class="d-block w-100 "
                      src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FWelcomeBanner05.png?alt=media&token=1dc73ab8-c9c0-469f-b968-5e102980ab4d"
                      alt="First slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src={calendario.imgCalendario02}
                      alt="Second slide"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      class="d-block w-100"
                      src={calendario.imgCalendario03}
                      alt="Third slide"
                    />
                  </div>
                </div>
              );
            })}
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
