import React, { useEffect, useState } from "react";
import CardQualification from "../CardQualification/CardQualification";
import { db } from "../../firebase";
import "./SchoolReport.css";

const SchoolReport = (props) => {
  const { userActive } = props;
  //useState
  const [firstBimester, setFirstBimester] = useState([]);
  const [secondBimester, setSecondBimester] = useState([]);
  const [thirdBimester, setThirdBimester] = useState([]);
  const [fourthBimester, setFourthBimester] = useState([]);
  const [fifthBimester, setFifthBimester] = useState([]);
  // useEffect
  //Trae las calificaciones del Usuario activo
  //useEffect
  useEffect(() => {
    const getUserQualifications = async () => {
      try {
        const userQualificationsActive = db
          .collection("calificaciones")
          .doc(userActive.email)
          .collection("bimestres24-25");
        await userQualificationsActive.get().then(function (querySnapshot) {
          console.log(querySnapshot);
          if (querySnapshot.docs.length > 0) {
            setFirstBimester(querySnapshot.docs[1].data());
            setSecondBimester(querySnapshot.docs[3].data());
            setThirdBimester(querySnapshot.docs[4].data());
            setFourthBimester(querySnapshot.docs[0].data());
            setFifthBimester(querySnapshot.docs[2].data());
          } else {
            console.log("Error al traer las calificaciones");
          }
        });
      } catch (error) {}
    };
    getUserQualifications();
  }, [userActive]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="historial-academico margin-top text-center">
            Historial Académico
          </h2>
          <CardQualification
            civicaBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141558/Civica_b8math.jpg"
            circulosBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Circulos_Lectura_hqearb.jpg"
            cienciasBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Ciencias_igar5e.jpg"
            saludBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Salud_qsivej.jpg"
            inglesBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Ingles_oe1hfs.jpg"
            exploradoresBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Exploradores_jgqyiq.jpg"
            matematicasBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Matematicas_z8vkvs.jpg"
            geografiaBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Geografia_aamdzx.jpg"
            lenguajeBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/LneguajeOrtografia_b6uow2.jpg"
            historiaBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1596141557/Historia_vmqwrg.jpg"
            habilidadesMotricesBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233006/HabilidadesMotrices_b6dnhk.jpg"
            artesBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233006/Artes_c90cjl.jpg"
            lenguajeEscritoBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233006/LenguajeEscrito_rfhyyi.jpg"
            lenguajeOralBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233006/LengujeOral_loqrit.jpg"
            educacionFisicaBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233005/EducacionFisica_kqvgi7.jpg"
            asignaturaEstatalBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233005/AsignaturaEstatal_o27fc4.jpg"
            orientacionTutoriaBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598563339/OrientacionYTutoria_hsue6k.jpg"
            reportesBg="https://res.cloudinary.com/dz5tntwl1/image/upload/v1598233005/Reportes_yh6jcs.jpg"
            firstBimester={firstBimester}
            secondBimester={secondBimester}
            thirdBimester={thirdBimester}
            fourthBimester={fourthBimester}
            fifthBimester={fifthBimester}
          />
        </div>
      </div>
    </div>
  );
};

export default SchoolReport;
