import React, { useState } from "react";
import "./Articles.css";
import Swal from "sweetalert2";
import { db } from "../../firebase";
import Zoom from "react-reveal/Zoom";
import ReactPlayer from "react-player";

const TasksProfessor = (props) => {
  const { userData } = props;
  //useState
  const [materiaSearch, setMateriaSearch] = useState("");
  const [semanaSearch, setSemanaSearch] = useState("0");
  const [taskSelected, setTaskSelected] = useState({});
  // const [profesorSearch, setProfesorSearch] = useState("");

  //funciónpara buscar una tarea por materia y por semana
  const handleMateriaSearch = async (event) => {
    event.preventDefault();
    // if (!profesorSearch.trim()) {
    //   Swal.fire({
    //     title: "¡Error!",
    //     text: "Debes agregar el nombre del profesor",
    //     icon: "warning",
    //     confirmButtonText: "Ok",
    //   });
    //   return;
    // }
    if (!materiaSearch.trim()) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar el nombre de la materia",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (semanaSearch < 0 || !semanaSearch) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar una semana",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    if (materiaSearch === "selecciona una opción por favor") {
      Swal.fire({
        title: "¡Error!",
        text: "La materia no es válida",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    // if (profesorSearch === "selecciona una opción por favor") {
    //   Swal.fire({
    //     title: "¡Error!",
    //     text: "El profesor no es válido",
    //     icon: "warning",
    //     confirmButtonText: "Ok",
    //   });
    //   return;
    // }
    try {
      const taskStudentContain = db
        .collection(`${materiaSearch}_22-23`)
        .doc(
          `Semana${semanaSearch}_${
            userData.grado
          }_${materiaSearch.toLowerCase()}`
        );
      await taskStudentContain.get().then(function (doc) {
        if (doc.exists && doc.data().Tarea.publicarAlumno) {
          setTaskSelected(doc.data().Tarea);
          setMateriaSearch("");
          setSemanaSearch("");
          // setProfesorSearch("");
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "La tarea no existe o no está disponible",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        return;
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4 text-center margin-top">Tareas</h2>
          <Zoom>
            <div className="tasks-professor__title">
              <h4>
                Puedes consultar una tarea seleccionando la materia y semana
              </h4>
            </div>
          </Zoom>
          <div className="col-md-12 tasks-container">
            <form onSubmit={handleMateriaSearch}>
              <div className="form-group ">
                {/* <Zoom>
                  <label className="margin-top-tasks">Profesor</label>
                  <select
                    className="form-control mb-4"
                    value={profesorSearch}
                    onChange={(event) => setProfesorSearch(event.target.value)}
                  >
                    {userData.profesores.map((profesor, index) => {
                      return (
                        <option translate="no" key={index}>
                          {profesor}
                        </option>
                      );
                    })}
                  </select>
                </Zoom> */}
                <Zoom>
                  <label>Materia</label>
                  <select
                    className="form-control mb-4"
                    value={materiaSearch}
                    onChange={(event) => setMateriaSearch(event.target.value)}
                  >
                    {userData.materias.map((materia, index) => {
                      return (
                        <option translate="no" key={index}>
                          {materia}
                        </option>
                      );
                    })}
                  </select>
                </Zoom>
                <Zoom>
                  <label>Semana</label>
                  <input
                    type="number"
                    placeholder="Ingresa la semana"
                    className="form-control mb-4"
                    value={semanaSearch}
                    onChange={(event) => setSemanaSearch(event.target.value)}
                  />
                </Zoom>
              </div>
              <button className="btn-searchTask mt-3 " type="submit">
                Buscar
              </button>
            </form>
          </div>
        </div>
        {taskSelected && (
          <div className="col-md-12 mt-5">
            <div className="task-selected-container">
              {taskSelected.materia && (
                <h2>{taskSelected.materia.toUpperCase()}</h2>
              )}
              {taskSelected.semana && (
                <p className="task-selected-week">
                  <span>
                    Semana: <span></span>
                  </span>
                  {taskSelected.semana}
                </p>
              )}
              {taskSelected.fechaEntrega && (
                <div className="task-selected-fecha">
                  <p>
                    Fecha límite de entrega:{" "}
                    <span>{taskSelected.fechaEntrega}</span>
                  </p>
                </div>
              )}
              {taskSelected.contenido && <p>{taskSelected.contenido}</p>}
              {taskSelected.urlFilePDF1 && (
                <div className="task-selected-url">
                  <p>Archivos PDF de Apoyo:</p>
                  <a
                    href={taskSelected.urlFilePDF1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    1- Archivo PDF
                  </a>
                </div>
              )}
              {taskSelected.urlFilePDF2 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.urlFilePDF2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2- Archivo PDF
                  </a>
                </div>
              )}
              {taskSelected.urlFilePDF3 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.urlFilePDF3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3- Archivo PDF
                  </a>
                </div>
              )}
              {taskSelected.urlFilePDF4 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.urlFilePDF4}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    4- Archivo PDF
                  </a>
                </div>
              )}
              {taskSelected.urlFilePDF5 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.urlFilePDF5}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    5- Archivo PDF
                  </a>
                </div>
              )}
              {taskSelected.urlFilePDF6 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.urlFilePDF6}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    6- Archivo PDF
                  </a>
                </div>
              )}
              {taskSelected.linkArchivo1 && (
                <div className="task-selected-url">
                  <p>Enlaces extra:</p>
                  <a
                    href={taskSelected.linkArchivo1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    1- Enlace
                  </a>
                </div>
              )}
              {taskSelected.linkArchivo2 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.linkArchivo2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2- Enlace
                  </a>
                </div>
              )}
              {taskSelected.linkArchivo3 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.linkArchivo3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3- Enlace
                  </a>
                </div>
              )}
              {taskSelected.linkArchivo4 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.linkArchivo4}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    4- Enlace
                  </a>
                </div>
              )}
              {taskSelected.linkArchivo5 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.linkArchivo5}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    5- Enlace
                  </a>
                </div>
              )}
              {taskSelected.linkArchivo6 && (
                <div className="task-selected-url">
                  <a
                    href={taskSelected.linkArchivo6}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    6- Enlace
                  </a>
                </div>
              )}
              <div className="videos-container">
                {taskSelected.linkVideo1 && (
                  <div className="task-selected-video">
                    <ReactPlayer
                      url={taskSelected.linkVideo1.toString()}
                      controls={true}
                      width="100%"
                    />
                  </div>
                )}
                {taskSelected.linkVideo2 && (
                  <div className="task-selected-video">
                    <ReactPlayer
                      url={taskSelected.linkVideo2.toString()}
                      controls={true}
                      width="100%"
                    />
                  </div>
                )}
                {taskSelected.linkVideo3 && (
                  <div className="task-selected-video">
                    <ReactPlayer
                      url={taskSelected.linkVideo3.toString()}
                      controls={true}
                      width="100%"
                    />
                  </div>
                )}
                {taskSelected.linkVideo4 && (
                  <div className="task-selected-video">
                    <ReactPlayer
                      url={taskSelected.linkVideo4.toString()}
                      controls={true}
                      width="100%"
                    />
                  </div>
                )}
                {taskSelected.linkVideo5 && (
                  <div className="task-selected-video">
                    <ReactPlayer
                      url={taskSelected.linkVideo5.toString()}
                      controls={true}
                      width="100%"
                    />
                  </div>
                )}
                {taskSelected.linkVideo6 && (
                  <div className="task-selected-video">
                    <ReactPlayer
                      url={taskSelected.linkVideo6.toString()}
                      controls={true}
                      width="100%"
                    />
                  </div>
                )}
              </div>
              {taskSelected.linkSesionZoom && (
                <div className="url-zoom-container">
                  <p>
                    {" "}
                    <span
                      style={{ fontweight: "bold", color: "rgb(255, 24, 24)" }}
                    >
                      ¡Importante Sesión 1!{" "}
                    </span>{" "}
                    Recuerda que tienes una sesión programada en Zoom el{" "}
                    <span>{taskSelected.sesionDate}</span> Puedes acceder a
                    través del siguiente{" "}
                    <a
                      href={taskSelected.linkSesionZoom}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </p>
                </div>
              )}
              {taskSelected.linkSesionZoom2 && (
                <div className="url-zoom-container">
                  <p>
                    {" "}
                    <span
                      style={{ fontweight: "bold", color: "rgb(255, 24, 24)" }}
                    >
                      ¡Importante Sesión 2!{" "}
                    </span>{" "}
                    Recuerda que tienes una sesión programada en Zoom el{" "}
                    <span>{taskSelected.sesionDate2}</span> Puedes acceder a
                    través del siguiente{" "}
                    <a
                      href={taskSelected.linkSesionZoom2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TasksProfessor;
