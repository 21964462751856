import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MenuBio from "../components/MenuBio/MenuBio";
import Calendar from "../components/Calendar/Calendar";
import Profile from "../components/Profile/Profile";
import SchoolReport from "../components/SchoolReport/SchoolReport";
import Articles from "../components/Articles/Articles";
import Article from "../components/Article/Article.jsx";
import Homework from "../components/Homework/Homework";
import Advice from "../components/Advice/Advice";
import Platform from "../components/Platform/Platform";
import Profesor from "../pages/Profesor";
import RegistrationCost from "../components/RegistrationCost/RegistrationCost";
import Footer from "../components/Footer/Footer";
import TasksProfessor from "../components/Profesor/TasksProfessor/TasksProfessor";
import EditTask from "../components/Profesor/EdiTask/EditTask";
import Reports from "../components/Profesor/Reports/Reports";
import WelcomePlattform from "../components/WelcomePlattform/WelcomePlattform";
import EnglishReports from "../components/EnglishReports/EnglishReports";

const BioContain = (props) => {
  const { userData, userActive } = props;

  return (
    <BrowserRouter>
      <MenuBio userData={userData} />
      <div className="container-contain">
        <Fragment>
          <Switch>
            <Route path="/home/welcome" exact>
              <WelcomePlattform />
            </Route>
            {userData.idRole === "profesor" ? (
              <Fragment>
                <Route path="/home/add-task" exact>
                  <Profesor userData={userData} />
                </Route>
                <Route path="/home/task-history" exact>
                  <TasksProfessor userData={userData} />
                </Route>
                <Route path="/home/edit-task" exact>
                  <EditTask userData={userData} />
                </Route>
                <Route path="/home/reports" exact>
                  <Reports userData={userData} />
                </Route>
              </Fragment>
            ) : (
              <Fragment>
                <Route path="/home/calendar" exact>
                  <Calendar />
                </Route>
                <Route path="/home/profile" exact>
                  <Profile userData={userData} />
                </Route>
                <Route path="/home/school-report" exact>
                  <SchoolReport userActive={userActive} />
                </Route>
                <Route path={`/home/${userData.idGrade}`} exact>
                  <Articles userData={userData} />
                </Route>
                <Route path={`/home/${userData.idGrade}/:id`} exact>
                  <Article userData={userData} />
                </Route>
                <Route path="/home/homework" exact>
                  <Homework userData={userData} userActive={userActive} />
                </Route>
                <Route path="/home/advice" exact>
                  <Advice userData={userData} userActive={userActive} />
                </Route>
                <Route path="/home/registration-process" exact>
                  <RegistrationCost userData={userData} />
                </Route>
                <Route path="/home/cambridge-platform" exact>
                  <Platform />
                </Route>
                <Route path="/home/english-reports" exact>
                  <EnglishReports userData={userData} />
                </Route>
              </Fragment>
            )}
          </Switch>
        </Fragment>
      </div>
      <Footer />
    </BrowserRouter>
  );
};

export default BioContain;
