import React from "react";
import Zoom from "react-reveal/Zoom";
import "./Platform.css";

const Platform = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center margin-top">
            Accede a la Plataforma de Cambridge
          </h2>
          <div className="platform-container">
            <Zoom>
              <div className="platform-card">
                <div className="platform-card__img">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FCambridgeWallpaper.jpg?alt=media&token=e7e182c7-4691-47f7-963c-3ee74600e653"
                    alt="Cambridge Universoty"
                  />
                </div>
                <div className="platform-card__texts">
                  <div className="platform-card__texts-button">
                    <a
                      href="https://www.cambridgeone.org/home"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Primaria
                    </a>
                    <div className="platform-card__links-container">
                      <p className="audio-help"> Audios de apoyo: </p>
                      <div className="platform-card__links">
                        <p>1ro Primaria</p>
                        <a
                          href="https://drive.google.com/drive/folders/1g9w7GiU2jsbO2TGZm7X_qhFPweV5OO_2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </div>
                      <div className="platform-card__links">
                        <p>2do y 3ro Primaria</p>
                        <a
                          href="https://drive.google.com/drive/folders/1n12Z9EWres9AIK1TGFBejMszS0gf3vxS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </div>
                      <div className="platform-card__links">
                        <p>4to y 5to Primaria</p>
                        <a
                          href="https://drive.google.com/drive/folders/1_YDgWfgTyUyorrY-dCdQH7NGUvo2yJVN"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </div>
                      <div className="platform-card__links">
                        <p>6to Primaria</p>
                        <a
                          href="https://drive.google.com/drive/folders/18T_r9iKzcjhCQmDLOrnXI3-P3TGUoJHz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
            <Zoom>
              <div className="platform-card">
                <div className="platform-card__img">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FCambridgeWallpaper.jpg?alt=media&token=e7e182c7-4691-47f7-963c-3ee74600e653"
                    alt="Cambridge Universoty"
                  />
                </div>
                <div className="platform-card__texts">
                  <div className="platform-card__texts-button">
                    <a
                      href="https://www.cambridgeone.org/home"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Secundaria
                    </a>
                    <div className="platform-card__links-container">
                      <p className="audio-help">Audios de apoyo: </p>
                      <div className="platform-card__links">
                        <p>1ro, 2do y 3ro Secundaria</p>
                        <a
                          href="https://drive.google.com/drive/folders/11Ws8o0BJYhnKVfyfGYVk7l0OfcW2ZCbB"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
