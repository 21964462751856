import app from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUBJAET71aY2tTQ6V7wSG5ukblwWKMQJ0",
  authDomain: "cehf-mx.firebaseapp.com",
  databaseURL: "https://cehf-mx.firebaseio.com",
  projectId: "cehf-mx",
  storageBucket: "cehf-mx.appspot.com",
  messagingSenderId: "909321357790",
  appId: "1:909321357790:web:bf42b76839f1b89092754f",
};
// Inicializar Firebase
app.initializeApp(firebaseConfig);

const db = app.firestore();
const auth = app.auth();
const storage = app.storage();
export { db, auth , storage};