import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Flash from "react-reveal/Flash";
import "./MenuBio.css";

const MenuBio = (props) => {
  const {
    userData: { idGrade, idRole, notPlattform, permissions, notEnglishReports },
  } = props;

  return (
    <div className="container menubio">
      <div className="row">
        <div className="col-md-12">
          <h1 className="text-center menubio-title">Menú</h1>
          <div className="menubio-container">
            {idRole === "profesor" ? (
              <Fragment>
                <Link to="/home/add-task" className="menubio-contain">
                  <Flash>
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Ftareas002.svg?alt=media&token=15a3cd04-ba8e-45b5-b8bc-20e6542be20f"
                        alt="Icono Agregar Tarea"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Agregar Tareas</p>
                      <p>
                        <span>Agrega una tarea</span>
                      </p>
                    </div>
                  </Flash>
                </Link>
                <Link to="/home/task-history" className="menubio-contain">
                  <Flash>
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FiconAddTask.svg?alt=media&token=381f783a-f467-4ca2-a14e-66ce5a058342"
                        alt="Icono Agregar Tarea"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Historial de Tareas</p>
                      <p>
                        <span>Consulta una tarea</span>
                      </p>
                    </div>
                  </Flash>
                </Link>
                <Link to="/home/edit-task" className="menubio-contain">
                  <Flash>
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Flista-de-verificacion.svg?alt=media&token=1206efa7-c99a-4b21-bc50-326c373c1edd"
                        alt="Icono Editar Tarea"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Edición de Tareas</p>
                      <p>
                        <span>Edita una tarea</span>
                      </p>
                    </div>
                  </Flash>
                </Link>
                {permissions === "director" && (
                  <Link to="/home/reports" className="menubio-contain">
                    <Flash>
                      <div className="menubio-contain__icon">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Freporte-de-alumnos.png?alt=media&token=60882d4c-5984-48c1-88bb-bb2df04887c1"
                          alt="Reportes"
                        />
                      </div>
                      <div className="menubio-contain__text">
                        <p>Reportes</p>
                        <p>
                          <span>Agrega reportes semanales</span>
                        </p>
                      </div>
                    </Flash>
                  </Link>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {" "}
                <Link to="/home/profile" className="menubio-contain">
                  <Flash className="menubio-animation">
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Festudiante.svg?alt=media&token=a2f48668-d02a-478c-8618-96843aa39c79"
                        alt="Icono Perfil"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Alumno</p>
                      {/* <p>
                        <span>Información del Alumno</span>
                      </p> */}
                    </div>
                  </Flash>
                </Link>
                <Link to="/home/calendar" className="menubio-contain">
                  <Flash className="menubio-animation">
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Fcalendario.svg?alt=media&token=b45f4efe-37fc-4936-9e79-88d6b7505a79"
                        alt="Icono Calendario"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Calendario</p>
                      {/* <p>
                        <span>Mira los próximos eventos</span>
                      </p> */}
                    </div>
                  </Flash>
                </Link>
                <Link to={`/home/${idGrade}`} className="menubio-contain">
                  <Flash className="menubio-animation">
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Fperiodico.svg?alt=media&token=7417ad39-83f1-4bd2-ba67-3f7f42121c66"
                        alt="Icono Calendario"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Tareas pendientes</p>
                      {/* <p>
                        <span>Revisa tus tareas pendientes</span>
                      </p> */}
                    </div>
                  </Flash>
                </Link>
                <Link to="/home/school-report" className="menubio-contain">
                  <Flash>
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Fgrado.svg?alt=media&token=ed847fcc-0960-4587-887d-061e28e1ff7b"
                        alt="Icono Calificaciones"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Historial Académico</p>
                      {/* <p>
                        <span>Revisa tu Historial Académico</span>
                      </p> */}
                    </div>
                  </Flash>
                </Link>
                <Link to="/home/homework" className="menubio-contain">
                  <Flash>
                    <div className="menubio-contain__icon">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Fdeberes.svg?alt=media&token=2a468d1f-e571-40ba-8dbd-e5966d11b5c4"
                        alt="Icono Calificaciones"
                      />
                    </div>
                    <div className="menubio-contain__text">
                      <p>Enviar tareas</p>
                      {/* <p>
                        <span>Envía tus tareas pendientes</span>
                      </p> */}
                    </div>
                  </Flash>
                </Link>
                {/* <Link to="/home/advice" className="menubio-contain">
                  <div className="menubio-contain__icon">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FiconAdvice.svg?alt=media&token=4f2a4e78-e8c5-4d03-9993-83560ebe80d0"
                      alt="Icono Asesorías"
                    />
                  </div>
                  <div className="menubio-contain__text">
                    <p>Asesorías</p>
                    <p>
                      <span>Agenda una asesoría</span>
                    </p>
                  </div>
                </Link> */}
                {/* <Link
                  to="/home/registration-process"
                  className="menubio-contain"
                >
                  <div className="menubio-contain__icon">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Fnotifiation-icon-cehf.svg?alt=media&token=8a578c97-6701-4564-929c-d1c7784a026c"
                      alt="Informes de Reinscripción"
                    />
                  </div>
                  <div className="menubio-contain__text">
                    <p>Reinscripción</p>
                    <p>
                      <span>Informes sobre la reinscripción</span>
                    </p>
                  </div>
                </Link> */}
                {notPlattform ? null : (
                  <Link
                    to="/home/cambridge-platform"
                    className="menubio-contain"
                  >
                    <Flash>
                      <div className="menubio-contain__icon">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FiconIngles.svg?alt=media&token=60c1a452-f283-41e9-8b54-e0cfabecf67c"
                          alt="Plataforma Cambridge"
                        />
                      </div>
                      <div className="menubio-contain__text">
                        <p>Plataforma de Cambridge</p>
                        {/* <p>
                          <span>Ingresa a la plataforma de Cambridge</span>
                        </p> */}
                      </div>
                    </Flash>
                  </Link>
                )}
                {notEnglishReports ? null : (
                  <Link to="/home/english-reports" className="menubio-contain">
                    <Flash>
                      <div className="menubio-contain__icon">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FiconIngles.svg?alt=media&token=60c1a452-f283-41e9-8b54-e0cfabecf67c"
                          alt="Plataforma Cambridge"
                        />
                      </div>
                      <div className="menubio-contain__text">
                        <p>Reportes de Inglés</p>
                      </div>
                    </Flash>
                  </Link>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBio;
