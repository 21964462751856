import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logocehf2.png";
import { db } from "../../firebase";
import "./RegistrationCost.css";

const RegistrationCost = (props) => {
  const { userData } = props;
  //state
  const [infoInscription, setInfoInscription] = useState({});

  //useEffect
  useEffect(() => {
    const getInfoInscription = async () => {
      try {
        const infoInscriptionGrade = db
          .collection("infoInscripcion")
          .doc(userData.siguienteGrado);
        await infoInscriptionGrade.get().then(function (doc) {
          if (doc.exists) {
            setInfoInscription(doc.data());
          }
        });
      } catch (error) {
        console.log("Hubo un error al traer la información de la inscripción");
      }
    };

    getInfoInscription();
  }, [userData.siguienteGrado]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2>Reinscripción</h2>
          <div className="table-costs">
            <div className="table-costs__logo">
              <img src={Logo} alt="Logo Centro Educativo los Héroes de la Fe" />
            </div>
            <p className="table-costs__text">
              Información sobre el proceso de reinscripción a{" "}
              {userData.siguienteGrado}
            </p>
            <ul>
              {!infoInscription.inscripcion ? null : (
                <li>
                  <p>
                    Costo de Inscripción:{" "}
                    <span>{infoInscription.inscripcion}</span>
                  </p>
                </li>
              )}
              {!infoInscription.colegiatura ? null : (
                <li>
                  <p>
                    Colegiatura Mensual:
                    <span> </span> <span>{infoInscription.colegiatura}</span>
                  </p>
                </li>
              )}
              {!infoInscription.libros ? null : (
                <li>
                  <p>
                    Costo de Libros: <span>{infoInscription.libros}</span>
                  </p>
                </li>
              )}
              {!infoInscription.certificacion ? null : (
                <li>
                  <p>
                    Certificación: <span>{infoInscription.certificacion}</span>
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationCost;
