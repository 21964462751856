import React, { useState, useCallback, Fragment } from "react";
import { auth } from "../../firebase";
import { withRouter } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import "./Login.css";


const Login = (props) => {
  const {
    history: { push },
  } = props;
  const [emailUser, setEmailUser] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [error, setError] = useState(null);
  const [seePassword, setSeePassword] = useState(false);

  // Función para procesar los datos de inicio de sesión
  const processData = (event) => {
    event.preventDefault();
    if (!emailUser.trim()) {
      setError("Ingresa un email");
      return;
    }
    if (!passwordUser.trim()) {
      setError("Debes ingresar una contraseña");
      return;
    }

    userLogin();
  };

  //Función para ver el password 
  const handleSeePassword = () => {
    setSeePassword(!seePassword);
  }

  // Función para iniciar sesión
  const userLogin = useCallback(async () => {
    try {
      await auth.signInWithEmailAndPassword(emailUser, passwordUser);
      setEmailUser("");
      setPasswordUser("");
      setError(null);
      push("/home/welcome");
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError("Email no válido");
      }
      if (error.code === "auth/user-not-found") {
        setError("Ninguna cuenta coincide con este email");
      }
      if (error.code === "auth/wrong-password") {
        setError("Contraseña incorrecta");
      }
    }
  }, [emailUser, passwordUser, push]);

  return (
    <Fragment>
      <div className="login-bg">
        <div className="login-box">
          <div className="login-box__text">
            <h3>Inicia Sesión</h3>
          </div>
          {error && (
            <div className="alert alert-danger text-center">{error}</div>
          )}
          <form onSubmit={processData}>
            <div className="login-box__inputs">
              <Zoom>
                <input
                  type="text"
                  placeholder="Correo"
                  onChange={(event) => setEmailUser(event.target.value)}
                />
              </Zoom>
              <Zoom>
                <input
                  type={seePassword ? "text" : "password"}
                  placeholder="Contraseña"
                  onChange={(event) => setPasswordUser(event.target.value)}
                />
              </Zoom>
              <Zoom> 
                <button onClick={  handleSeePassword } className="see-password"type="button" >{seePassword ? "Ocultar contraseña" : "Ver contraseña"}</button>
              </Zoom>
            </div>
            <Zoom>
              <div className="login-box__button">
                <button>Iniciar Sesión</button>
              </div>
            </Zoom>
          </form>
        </div>
      </div>
      <footer className="footer-login">
        <a href="/">
          <p>Políticas de Privacidad</p>
        </a>
      </footer>
    </Fragment>
  );
};

export default withRouter(Login);
