import React, { useState, useEffect, memo } from "react";
import { auth, db } from "../firebase";
import { withRouter } from "react-router-dom";
import Biography from "../components/Biography/Biography";
import BioContain from "../pages/BioContain";
import Loading from "../components/Loading/Loading";

const Home = (props) => {
  const {
    history: { push },
  } = props;
  // useState
  const [userActive, setUserActive] = useState(null);
  const [userData, setUserData] = useState({});
  // Determinar si hay un usuario activo, de lo contrario se enviará a la página de inicio "/"
  useEffect(() => {
        if (auth.currentUser) {
        setUserActive(auth.currentUser);
      } else {
        push("/login");
      }
  }, [push]);
  //Traer Data del Usuario activo
  useEffect(() => {
    const getUserData =  async () => {
        try {
          const userDataActive = db.collection("users").doc(userActive.email);
          await userDataActive.get().then(function (doc) {
            if (doc.exists) {
              setUserData(doc.data());
            }
          });
        } catch (error) {
          console.log(error);
        }    
    };
    getUserData();
  }, [userActive]);



  return userActive !== null ? (
    <div>
      <Biography userData={userData} />
      <BioContain
        userData={userData}
        userActive={userActive}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default withRouter(Home);
