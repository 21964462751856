import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { auth } from "../../firebase";
import "./StartPage.css";

const StartPage = (props) => {
  const {
    history: { push },
  } = props;
  // useEffect
  useEffect(() => {
    if (auth.currentUser) {
      push("/home");
    } else {
      push("/");
    }
  }, [push]);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="startpage">
          <div className="startpage-text">
            <div className="startpage-text__title">
              <h1>PLATAFORMA CEHF</h1>
            </div>
            <Link to="/login" className="startpage-button">
              <button>Inicia Sesión</button>
            </Link>
          </div>
          <div className="startpage-img">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FonlineLearning.svg?alt=media&token=7ce6bb53-2b85-4793-824d-46acbc00ba28"
              alt="Imagen Inicio CEHF"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(StartPage);
