import React, { useState, useEffect, Fragment } from "react";
import { auth } from "./firebase";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Login from "./components/Login/Login";
import Home from "./pages/Home";
import StartPage from "./components/StartPage/StartPage";
import Loading from "./components/Loading/Loading";




function App() {
  // useState
  const [userFirebase, setUserFirebase] = useState(false);
  // useEffect
  useEffect(() => {
    setTimeout(() => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setUserFirebase(user);
        } else {
          setUserFirebase(null);
        }
      });
    }, 1900);
  }, []);


  return userFirebase !== false ? (
    <BrowserRouter>
      <Navbar userFirebase={userFirebase} />
      <div className="container-app">
      <Fragment>
        <Switch>
          <Route path="/" exact>
            <StartPage />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/home">
            <Home />
          </Route>
        </Switch>
        </Fragment>
      </div>
    </BrowserRouter>
  ) : (
    <Loading />
  );
}

export default App;
