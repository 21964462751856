import React, { Fragment } from "react";
import "./CardQualification.css";

const CardQualification = (props) => {
  const {
    firstBimester,
    secondBimester,
    thirdBimester,
    fourthBimester,
    fifthBimester,
    civicaBg,
    circulosBg,
    cienciasBg,
    saludBg,
    inglesBg,
    exploradoresBg,
    matematicasBg,
    geografiaBg,
    lenguajeBg,
    historiaBg,
    habilidadesMotricesBg,
    artesBg,
    lenguajeEscritoBg,
    lenguajeOralBg,
    educacionFisicaBg,
    asignaturaEstatalBg,
    orientacionTutoriaBg,
    reportesBg,
  } = props;

  return (
    <Fragment>
      <div className="accordion" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingOne">
            <h5 className="mb-0">
              <button
                className="btn btn-link"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Primer Bimestre
              </button>
            </h5>
          </div>
          <div
            id="collapseOne"
            className="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="cards-alineacion">
                {!firstBimester.civica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={civicaBg} alt="Calificación Cívica" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.civica ? "N/A" : firstBimester.civica}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.circulosLectura ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={circulosBg}
                        alt="Calificación Circulos de lectura"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.circulosLectura
                          ? "N/A"
                          : firstBimester.circulosLectura}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.Ciencias ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={cienciasBg} alt="Calificación Ciencias" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.Ciencias
                          ? "N/A"
                          : firstBimester.Ciencias}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.LenguaExtranjera ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={inglesBg} alt="Calificación Exploradores" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.LenguaExtranjera
                          ? "N/A"
                          : firstBimester.LenguaExtranjera}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.exploradores ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={exploradoresBg}
                        alt="Calificación Exploradores"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.exploradores
                          ? "N/A"
                          : firstBimester.exploradores}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.salud ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={saludBg} alt="Calificación Salud" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.salud ? "N/A" : firstBimester.salud}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.Matemáticas ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={matematicasBg} alt="Calificación Matemáticas" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.Matemáticas
                          ? "N/A"
                          : firstBimester.Matemáticas}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.geografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={geografiaBg} alt="Calificación Geografía" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.geografia
                          ? "N/A"
                          : firstBimester.geografia}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.lenguajeOrtografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={lenguajeBg} alt="Calificación Lenguaje" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!firstBimester.lenguajeOrtografia
                          ? "N/A"
                          : firstBimester.lenguajeOrtografia}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.historia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={historiaBg} alt="Calificación Historia" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.historia
                          ? "N/A"
                          : firstBimester.historia}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.HabilidadesMotrices ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={habilidadesMotricesBg}
                        alt="Calificación Habilidades Motrices"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.HabilidadesMotrices
                          ? "N/A"
                          : firstBimester.HabilidadesMotrices}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.artes ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={artesBg} alt="Calificación Artes" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.artes ? "N/A" : firstBimester.artes}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.LenguajeEscrito ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeEscritoBg}
                        alt="Calificación Lenguaje Escrito"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.LenguajeEscrito
                          ? "N/A"
                          : firstBimester.LenguajeEscrito}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.LenguajeOral ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeOralBg}
                        alt="Calificación Lenguaje Oral"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.LenguajeOral
                          ? "N/A"
                          : firstBimester.LenguajeOral}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.educacionFisica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={educacionFisicaBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.educacionFisica
                          ? "N/A"
                          : firstBimester.educacionFisica}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.asignaturaEstatal ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={asignaturaEstatalBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.aisgnaturaEstatal
                          ? "N/A"
                          : firstBimester.asignaturaEstatal}
                      </p>
                    </div>
                  </div>
                )}
                {!firstBimester.orientacionTutoria ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={orientacionTutoriaBg}
                        alt="Calificación Orientación y Tutoria"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!firstBimester.orientacionTutoria
                          ? "N/A"
                          : firstBimester.orientacionTutoria}
                      </p>
                    </div>
                  </div>
                )}
                <div className="container-cards-calificaciones">
                  <div className="card-calificacion__bg">
                    <img src={reportesBg} alt="Calificación Educacion Fisica" />
                  </div>
                  <div className="card-calificacion__note">
                    <p>
                      <span>Reporte Semanal: </span>{" "}
                    </p>
                    {firstBimester.reportes && (
                      <button className="mb-3">
                        <a
                          href={firstBimester.reportes}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          REPORTE DE TAREAS
                        </a>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingTwo">
            <h5 className="mb-0">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Segundo Bimestre
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="cards-alineacion">
                {!secondBimester.civica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={civicaBg} alt="Calificación Cívica" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.civica ? "N/A" : secondBimester.civica}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.circulosLectura ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={circulosBg}
                        alt="Calificación Circulos de lectura"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.circulosLectura
                          ? "N/A"
                          : secondBimester.circulosLectura}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.ciencias ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={cienciasBg} alt="Calificación Ciencias" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.ciencias
                          ? "N/A"
                          : secondBimester.ciencias}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.ingles ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={inglesBg} alt="Calificación Exploradores" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.ingles ? "N/A" : secondBimester.ingles}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.exploradores ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={exploradoresBg}
                        alt="Calificación Exploradores"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.exploradores
                          ? "N/A"
                          : secondBimester.exploradores}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.salud ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={saludBg} alt="Calificación Salud" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.salud ? "N/A" : secondBimester.salud}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.matematicas ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={matematicasBg} alt="Calificación Matemáticas" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.matematicas
                          ? "N/A"
                          : secondBimester.matematicas}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.geografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={geografiaBg} alt="Calificación Geografía" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.geografia
                          ? "N/A"
                          : secondBimester.geografia}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.lenguajeOrtografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={lenguajeBg} alt="Calificación Lenguaje" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!secondBimester.lenguajeOrtografia
                          ? "N/A"
                          : secondBimester.lenguajeOrtografia}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.historia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={historiaBg} alt="Calificación Historia" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.historia
                          ? "N/A"
                          : secondBimester.historia}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.habilidadesMotrices ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={habilidadesMotricesBg}
                        alt="Calificación Habilidades Motrices"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.habilidadesMotrices
                          ? "N/A"
                          : secondBimester.habilidadesMotrices}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.artes ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={artesBg} alt="Calificación Artes" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.artes ? "N/A" : secondBimester.artes}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.lenguajeEscrito ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeEscritoBg}
                        alt="Calificación Lenguaje Escrito"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.lenguajeEscrito
                          ? "N/A"
                          : secondBimester.lenguajeEscrito}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.lenguajeOral ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeOralBg}
                        alt="Calificación Lenguaje Oral"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.lenguajeOral
                          ? "N/A"
                          : secondBimester.lenguajeOral}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.educacionFisica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={educacionFisicaBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.educacionFisica
                          ? "N/A"
                          : secondBimester.educacionFisica}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.asignaturaEstatal ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={asignaturaEstatalBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.aisgnaturaEstatal
                          ? "N/A"
                          : secondBimester.asignaturaEstatal}
                      </p>
                    </div>
                  </div>
                )}
                {!secondBimester.orientacionTutoria ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={orientacionTutoriaBg}
                        alt="Calificación Orientación y Tutoria"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!secondBimester.orientacionTutoria
                          ? "N/A"
                          : secondBimester.orientacionTutoria}
                      </p>
                    </div>
                  </div>
                )}
                <div className="container-cards-calificaciones">
                  <div className="card-calificacion__bg">
                    <img src={reportesBg} alt="Calificación Educacion Fisica" />
                  </div>
                  <div className="card-calificacion__note">
                    <p>
                      <span>Reporte Semanal: </span>{" "}
                    </p>
                    {secondBimester.reportes && (
                      <button className="mb-3">
                        <a
                          href={secondBimester.reportes}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          REPORTE DE TAREAS
                        </a>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingThree">
            <h5 className="mb-0">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Tercer Bimestre
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="cards-alineacion">
                {!thirdBimester.civica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={civicaBg} alt="Calificación Cívica" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.civica ? "N/A" : thirdBimester.civica}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.circulosLectura ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={circulosBg}
                        alt="Calificación Circulos de lectura"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.circulosLectura
                          ? "N/A"
                          : thirdBimester.circulosLectura}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.ciencias ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={cienciasBg} alt="Calificación Ciencias" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.ciencias
                          ? "N/A"
                          : thirdBimester.ciencias}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.ingles ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={inglesBg} alt="Calificación Exploradores" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.ingles ? "N/A" : thirdBimester.ingles}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.exploradores ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={exploradoresBg}
                        alt="Calificación Exploradores"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.exploradores
                          ? "N/A"
                          : thirdBimester.exploradores}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.salud ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={saludBg} alt="Calificación Salud" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.salud ? "N/A" : thirdBimester.salud}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.matematicas ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={matematicasBg} alt="Calificación Matemáticas" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.matematicas
                          ? "N/A"
                          : thirdBimester.matematicas}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.geografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={geografiaBg} alt="Calificación Geografía" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.geografia
                          ? "N/A"
                          : thirdBimester.geografia}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.lenguajeOrtografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={lenguajeBg} alt="Calificación Lenguaje" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!thirdBimester.lenguajeOrtografia
                          ? "N/A"
                          : thirdBimester.lenguajeOrtografia}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.historia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={historiaBg} alt="Calificación Historia" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.historia
                          ? "N/A"
                          : thirdBimester.historia}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.habilidadesMotrices ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={habilidadesMotricesBg}
                        alt="Calificación Habilidades Motrices"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.habilidadesMotrices
                          ? "N/A"
                          : thirdBimester.habilidadesMotrices}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.artes ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={artesBg} alt="Calificación Artes" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.artes ? "N/A" : thirdBimester.artes}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.lenguajeEscrito ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeEscritoBg}
                        alt="Calificación Lenguaje Escrito"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.lenguajeEscrito
                          ? "N/A"
                          : thirdBimester.lenguajeEscrito}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.lenguajeOral ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeOralBg}
                        alt="Calificación Lenguaje Oral"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.lenguajeOral
                          ? "N/A"
                          : thirdBimester.lenguajeOral}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.educacionFisica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={educacionFisicaBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.educacionFisica
                          ? "N/A"
                          : thirdBimester.educacionFisica}
                      </p>
                    </div>
                  </div>
                )}
                {!thirdBimester.asignaturaEstatal ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={asignaturaEstatalBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!thirdBimester.aisgnaturaEstatal
                          ? "N/A"
                          : thirdBimester.asignaturaEstatal}
                      </p>
                    </div>
                  </div>
                )}
                <div className="container-cards-calificaciones">
                  <div className="card-calificacion__bg">
                    <img src={reportesBg} alt="Calificación Educacion Fisica" />
                  </div>
                  <div className="card-calificacion__note">
                    <p>
                      <span>Reporte Semanal:</span>{" "}
                    </p>
                    {!thirdBimester.reportes ? null : (
                      <button>
                        <a
                          href={thirdBimester.reportes}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          REPORTE DE TAREAS
                        </a>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingFourth">
            <h5 className="mb-0">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFourth"
                aria-expanded="false"
                aria-controls="collapseFourth"
              >
                Cuarto Bimestre
              </button>
            </h5>
          </div>
          <div
            id="collapseFourth"
            className="collapse"
            aria-labelledby="headingFourth"
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="cards-alineacion">
                {!fourthBimester.civica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={civicaBg} alt="Calificación Cívica" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.civica ? "N/A" : fourthBimester.civica}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.circulosLectura ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={circulosBg}
                        alt="Calificación Circulos de lectura"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.circulosLectura
                          ? "N/A"
                          : fourthBimester.circulosLectura}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.ciencias ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={cienciasBg} alt="Calificación Ciencias" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.ciencias
                          ? "N/A"
                          : fourthBimester.ciencias}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.ingles ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={inglesBg} alt="Calificación Exploradores" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.ingles ? "N/A" : fourthBimester.ingles}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.exploradores ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={exploradoresBg}
                        alt="Calificación Exploradores"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.exploradores
                          ? "N/A"
                          : fourthBimester.exploradores}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.salud ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={saludBg} alt="Calificación Salud" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.salud ? "N/A" : fourthBimester.salud}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.matematicas ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={matematicasBg} alt="Calificación Matemáticas" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.matematicas
                          ? "N/A"
                          : fourthBimester.matematicas}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.geografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={geografiaBg} alt="Calificación Geografía" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.geografia
                          ? "N/A"
                          : fourthBimester.geografia}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.lenguajeOrtografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={lenguajeBg} alt="Calificación Lenguaje" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fourthBimester.lenguajeOrtografia
                          ? "N/A"
                          : fourthBimester.lenguajeOrtografia}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.historia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={historiaBg} alt="Calificación Historia" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.historia
                          ? "N/A"
                          : fourthBimester.historia}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.habilidadesMotrices ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={habilidadesMotricesBg}
                        alt="Calificación Habilidades Motrices"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.habilidadesMotrices
                          ? "N/A"
                          : fourthBimester.habilidadesMotrices}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.artes ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={artesBg} alt="Calificación Artes" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.artes ? "N/A" : fourthBimester.artes}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.lenguajeEscrito ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeEscritoBg}
                        alt="Calificación Lenguaje Escrito"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.lenguajeEscrito
                          ? "N/A"
                          : fourthBimester.lenguajeEscrito}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.lenguajeOral ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeOralBg}
                        alt="Calificación Lenguaje Oral"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.lenguajeOral
                          ? "N/A"
                          : fourthBimester.lenguajeOral}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.educacionFisica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={educacionFisicaBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.educacionFisica
                          ? "N/A"
                          : fourthBimester.educacionFisica}
                      </p>
                    </div>
                  </div>
                )}
                {!fourthBimester.asignaturaEstatal ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={asignaturaEstatalBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fourthBimester.aisgnaturaEstatal
                          ? "N/A"
                          : fourthBimester.asignaturaEstatal}
                      </p>
                    </div>
                  </div>
                )}
                <div className="container-cards-calificaciones">
                  <div className="card-calificacion__bg">
                    <img src={reportesBg} alt="Calificación Educacion Fisica" />
                  </div>
                  <div className="card-calificacion__note">
                    <p>
                      <span>Reporte Semanal: </span>{" "}
                    </p>
                    {!fourthBimester.reportes ? null : (
                      <button>
                        <a
                          href={fourthBimester.reportes}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          REPORTE DE TAREAS
                        </a>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="card">
          <div className="card-header" id="headingFifth">
            <h5 className="mb-0">
              <button
                className="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFifth"
                aria-expanded="false"
                aria-controls="collapseFifth"
              >
                Quinto Bimestre
              </button>
            </h5>
          </div>
          <div
            id="collapseFifth"
            className="collapse"
            aria-labelledby="headingFifth"
            data-parent="#accordionExample"
          >
            <div className="card-body">
              <div className="cards-alineacion">
                {!fifthBimester.civica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={civicaBg} alt="Calificación Cívica" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.civica ? "N/A" : fifthBimester.civica}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.circulosLectura ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={circulosBg}
                        alt="Calificación Circulos de lectura"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.circulosLectura
                          ? "N/A"
                          : fifthBimester.circulosLectura}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.ciencias ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={cienciasBg} alt="Calificación Ciencias" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.ciencias
                          ? "N/A"
                          : fifthBimester.ciencias}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.ingles ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={inglesBg} alt="Calificación Exploradores" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.ingles ? "N/A" : fifthBimester.ingles}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.exploradores ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={exploradoresBg}
                        alt="Calificación Exploradores"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.exploradores
                          ? "N/A"
                          : fifthBimester.exploradores}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.salud ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={saludBg} alt="Calificación Salud" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.salud ? "N/A" : fifthBimester.salud}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.matematicas ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={matematicasBg} alt="Calificación Matemáticas" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.matematicas
                          ? "N/A"
                          : fifthBimester.matematicas}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.geografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={geografiaBg} alt="Calificación Geografía" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.geografia
                          ? "N/A"
                          : fifthBimester.geografia}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.lenguajeOrtografia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={lenguajeBg} alt="Calificación Lenguaje" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>
                        {!fifthBimester.lenguajeOrtografia
                          ? "N/A"
                          : fifthBimester.lenguajeOrtografia}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.historia ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={historiaBg} alt="Calificación Historia" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.historia
                          ? "N/A"
                          : fifthBimester.historia}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.habilidadesMotrices ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={habilidadesMotricesBg}
                        alt="Calificación Habilidades Motrices"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.habilidadesMotrices
                          ? "N/A"
                          : fifthBimester.habilidadesMotrices}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.artes ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img src={artesBg} alt="Calificación Artes" />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.artes ? "N/A" : fifthBimester.artes}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.lenguajeEscrito ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeEscritoBg}
                        alt="Calificación Lenguaje Escrito"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.lenguajeEscrito
                          ? "N/A"
                          : fifthBimester.lenguajeEscrito}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.lenguajeOral ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={lenguajeOralBg}
                        alt="Calificación Lenguaje Oral"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.lenguajeOral
                          ? "N/A"
                          : fifthBimester.lenguajeOral}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.educacionFisica ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={educacionFisicaBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.educacionFisica
                          ? "N/A"
                          : fifthBimester.educacionFisica}
                      </p>
                    </div>
                  </div>
                )}
                {!fifthBimester.asignaturaEstatal ? null : (
                  <div className="container-cards-calificaciones">
                    <div className="card-calificacion__bg">
                      <img
                        src={asignaturaEstatalBg}
                        alt="Calificación Educacion Fisica"
                      />
                    </div>
                    <div className="card-calificacion__note">
                      <p>
                        <span>Calificación: </span>{" "}
                        {!fifthBimester.aisgnaturaEstatal
                          ? "N/A"
                          : fifthBimester.asignaturaEstatal}
                      </p>
                    </div>
                  </div>
                )}
                <div className="container-cards-calificaciones">
                  <div className="card-calificacion__bg">
                    <img src={reportesBg} alt="Calificación Educacion Fisica" />
                  </div>
                  <div className="card-calificacion__note">
                    <p>
                      <span>Reporte Semanal: </span>{" "}
                    </p>
                    {!fifthBimester.reportes ? null : (
                      <button>
                        <a
                          href={fifthBimester.reportes}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          REPORTE DE TAREAS
                        </a>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CardQualification;
