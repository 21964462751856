import React from "react";
import { Link, NavLink } from "react-router-dom";
import { auth } from "../../firebase";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/img/logocehf2.png";
import "./Navbar.css";

const Navbar = (props) => {
  const {
    history: { push },
    userFirebase,
  } = props;

  // Función para cerrar la sesión
  const userLogout = () => {
    auth.signOut().then(() => {
      push("/");
    });
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="navbar navbar-dark bg-dark">
          {userFirebase !== null ? (
            <Link to="/home" className="navbar-brand">
              <img src={Logo} alt="Logo Centro Educativo los Héroes de la Fe" />
            </Link>
          ) : (
            <Link to="/" className="navbar-brand">
              <img src={Logo} alt="Logo Centro Educativo los Héroes de la Fe" />
            </Link>
          )}
          <div className="d-flex">
            {/* {userFirebase !== null && (
              <NavLink
                to="/home"
                exact
                className="btn btn-dark mr-2 navbar-link"
              >
                Home
              </NavLink>
            )} */}
            {userFirebase !== null && (
              <NavLink
                onClick={userLogout}
                to="/login"
                exact
                className="btn btn-dark mr-2 navbar-link"
              >
                Cerrar Sesión
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
