import React, { useState, useEffect, Fragment } from "react";
import Zoom from "react-reveal/Zoom";
import "./Homework.css";

const Homework = (props) => {
  const { userData } = props;
  // useState
  const [submitDay, setSubmitDay] = useState(false);
  // useEffect
  // Función para obtener el día
  useEffect(() => {
    const getToday = () => {
      const date = new Date();
      const day = date.getDay();
      if (
        day === 0 ||
        day === 1 ||
        day === 2 ||
        day === 3 ||
        day === 4 ||
        day === 5 ||
        day === 6
      ) {
        setSubmitDay(true);
      } else {
        setSubmitDay(false);
      }
    };
    getToday();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 titles">
          <h2 className="margin-top text-center">Envía tus tareas</h2>
          {submitDay ? (
            <Fragment>
              <Zoom>
                <h4>
                  El envío de tareas sólo estará habilitado hasta el Viernes a
                  las 12 A.M. No olvides enviarlas a tiempo.
                </h4>
              </Zoom>
              <div className="container-options">
                <Zoom>
                  <div className="options-email">
                    <p>{userData.grado}</p>
                    <a
                      href={`mailto:${userData.idEmail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FemailHomework.svg?alt=media&token=81e5ad2f-9610-47be-b472-73b06be7a387"
                        alt="Email CEHF"
                      />
                    </a>
                  </div>
                </Zoom>
                {userData.idEmailEduc &&
                        <Zoom>
                        <div className="options-email">
                          <p>Educación Física</p>
                          <a
                            href={`mailto:${userData.idEmailEduc}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FemailHomework.svg?alt=media&token=81e5ad2f-9610-47be-b472-73b06be7a387"
                              alt="Email CEHF"
                            />
                          </a>
                        </div>
                      </Zoom>
                }
                {userData.idEmailIngles &&
                      <Zoom>
                        <div className="options-email">
                          <p>Inglés</p>
                          <a
                          href={`mailto:${userData.idEmailIngles}`}
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                        <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FemailHomework.svg?alt=media&token=81e5ad2f-9610-47be-b472-73b06be7a387"
                        alt="Email CEHF"
                      />
                        </a>
                        </div>
                    </Zoom>
                }
              </div>
            </Fragment>
          ) : (
            <div className="submitHomework">
              <h4>El envío de tareas se habilitará el Sábado a las 10 am</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Homework;
