import React, {useState, useEffect} from "react";
import Zoom from "react-reveal/Zoom";
import "./Biography.css";

const Biography = (props) => {
  const { userData } = props;

  //useState
  const [photoBiography, setPhotoBiography] = useState("");
  const [greetinBiography, setGreetingBiography] = useState("");

  //useEffect
  useEffect(()=> {
    const getHour = ( )=> {
      const fecha = new Date();
      const hora = fecha.getHours();
      console.log(hora);
      if (hora>=6 && hora< 13) {
        setPhotoBiography("https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/wallpapersBiography%2FwallpaperBiography02.png?alt=media&token=a6c67a45-e7d1-41d6-9c22-516d450d60fd");
        setGreetingBiography("¡Buenos Días!");  
      } else if (hora>=13 && hora < 20) { 
        setPhotoBiography("https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/wallpapersBiography%2FwallpaperBiography01.png?alt=media&token=3c285d88-95fa-420d-82d8-a8007c3012d6");
        setGreetingBiography("¡Buenas Tardes!");
      } else { 
        setPhotoBiography("https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/wallpapersBiography%2FwallpaperBiography03.png?alt=media&token=cfc455e0-bc03-4889-a356-3ffb84388a66");
        setGreetingBiography("¡Buenas Noches!");
      }
    }
    getHour();
  }, [])

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="container-bio">
          <div className="biography-header">
            <img
              src={photoBiography}
              alt="Imagen Biografía"
            />
          </div>
          <div className="biography-header__contain">
            <div className="biography-header__contain-text">
              <div className="biography-header__contain-text-name">
                <Zoom>
                  <p>
                    {greetinBiography}{" "}
                    <span>
                      {userData.nombre} {userData.primerApellido}
                    </span>
                  </p>
                </Zoom>
                <Zoom>
                  <div className="biography-header__contain-text-year">
                    <p>{userData.grado}</p>
                  </div>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="biography-header__avatar">
            <img src={userData.imgUrl} alt="Avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biography;
