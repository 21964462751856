import React, { useState } from "react";
import Swal from "sweetalert2";
import Zoom from "react-reveal/Zoom";
import { db } from "../../firebase";

const EnglishReports = (props) => {
  const { userData } = props;
  const [weekSearch, setWeekSearch] = useState("1");
  const [report, setReport] = useState({});

  const handleReport = async (event) => {
    event.preventDefault();
    if (!weekSearch || weekSearch < 1 || weekSearch > 52) {
      Swal.fire({
        title: "¡Error!",
        text: "Debes agregar la semana",
        icon: "warning",
        confirmButtonText: "Ok",
      });
      return;
    }
    try {
      const englishReportSearch = db
        .collection("reportes_ingles_24-25")
        .doc(
          `Semana${weekSearch}_${userData.nombre} ${userData.primerApellido} ${userData.segundoApellido}`
        );
      await englishReportSearch.get().then(function (doc) {
        if (doc.exists && doc.data().Reporte.publicarAlumno) {
          setReport(doc.data().Reporte);
          setWeekSearch("1");
          console.log("REPORTE", report);
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "El reporte no existe o no está disponible",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        return;
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4 text-center margin-top">
            Reportes semanales de Inglés
          </h2>
          <Zoom>
            <div className="tasks-professor__title">
              <h4>Puedes consultar un reporte seleccionando la semana</h4>
            </div>
          </Zoom>
          <div className="col-md-12">
            <form onSubmit={handleReport}>
              <div className="form-group">
                <Zoom>
                  <label>Semana</label>
                  <input
                    type="number"
                    placeholder="Ingresa la semana"
                    className="form-control mb-4"
                    value={weekSearch}
                    onChange={(event) => setWeekSearch(event.target.value)}
                  />
                </Zoom>
              </div>
              <button
                className="btn-searchTask mt-3 "
                type="button"
                onClick={handleReport}
              >
                Buscar
              </button>
            </form>
          </div>
          {report && (
            <div className="col-md-12">
              <object
                style={{ width: "100%", height: "600px", marginTop: "70px" }}
                data={report.urlFileReport}
                type="application/pdf"
              >
                <iframe
                  width={"100%"}
                  height={"600px"}
                  title={`Reporte de ${report.alumno}`}
                  src={report.urlFileReport}
                ></iframe>
              </object>
              {report.urlFileReport && (
                <div className="task-selected-url">
                  <a
                    href={report.urlFileReport}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Si no puedes ver el archivo da click aquí
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnglishReports;
