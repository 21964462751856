import React, { useState} from "react";
import AddTask from "../components/Profesor/AddTask/AddTask";



const Profesor = (props) => {
  const { userData} = props;
  return (
    <div className="container">
      <AddTask
        userData={userData}
      />
    </div>
  );
};

export default Profesor;
