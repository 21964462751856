import React from 'react'
import "./WelcomePlattform.css"


const WelcomePlattform = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h1 className="welcome-title text-center">Bienvenido a la Plataforma CEHF</h1> 
                <p className="welcome-subtitle text-center">Selecciona una opción para comenzar</p>
                </div>
            </div>
        </div>
    )
}

export default WelcomePlattform;
