import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <footer className="footer-app">
          <div className="footer-logo">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/logoLink.png?alt=media&token=27185fb4-1e29-4920-9fb1-ff09a85fe87c"
              alt="Logo CEHF"
            />
            <p>Centro Educativo los Héroes de la Fe</p>
          </div>
          <div className="footer-social">
            <a
              href="https://www.facebook.com/CentroEducativoHeroesdelaFe/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Ffacebook.png?alt=media&token=840b248a-ce9d-4c45-a3a5-099963315466"
                alt="Facebook CEHF"
              />
            </a>
            <a
              href="mailto:app.cehf@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2Femail.png?alt=media&token=05737ce0-2e39-4767-bebc-c85ed3591e96"
                alt="Email CEHF"
              />
            </a>
          </div>
          <p className="copyR">Plataforma CEHF V 1.5.10</p>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
