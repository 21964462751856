import React, { Fragment } from "react";
import Zoom from "react-reveal/Zoom";
import "./Profile.css";

const Profile = (props) => {
  const { userData } = props;
  return (
    <Fragment>
      <h2 className="text-center margin-top">Perfil</h2>
      <div className="profile">
        <div className="profile-info">
          <div className="profile-info__avatar">
            <img src={userData.imgUrl} alt={userData.nombre} />
            <div className="profile-info__avatar-texts">
              <p>
                {userData.nombre} {userData.primerApellido}
              </p>
              <p>
                <span>{userData.grado}</span>
              </p>
            </div>
          </div>
          <div className="profile-info__texts">
            <Zoom>
              <div className="profile-info__group">
                <div className="profile-group__text">
                  <p>
                    <span>
                      {userData.nombre} {userData.segundoNombre}{" "}
                      {userData.primerApellido} {userData.segundoApellido}
                    </span>
                  </p>
                  <p>Nombre Completo</p>
                </div>
                <div className="profile-group__text">
                  <p>
                    <span>2022-2023</span>
                  </p>
                  <p>Ciclo Escolar</p>
                </div>
              </div>{" "}
            </Zoom>
            {/* First Group*/}
            <Zoom>
              <div className="profile-info__group">
                <div className="profile-group__text">
                  <p>
                    <span>{userData.grado}</span>
                  </p>
                  <p>Grado</p>
                </div>
                <div className="profile-group__text">
                  <p>
                    <span>{!userData.promedio ? "NA" : userData.promedio}</span>
                  </p>
                  <p>Promedio</p>
                </div>
                <div className="profile-group__text">
                  <p>
                    <span>{userData.grupo}</span>
                  </p>
                  <p>Grupo</p>
                </div>
              </div>{" "}
            </Zoom>
            {/* Second Group*/}
            <Zoom>
              <div className="profile-info__group">
                <div className="profile-group__text">
                  <p>
                    <span>
                      {!userData.docsPendientes
                        ? "Ninguno"
                        : userData.docsPendientes}
                    </span>
                  </p>
                  <p>Documentos Pendientes</p>
                </div>
                <div className="profile-group__text">
                  <p>
                    <span>{userData.inasistencias}</span>
                  </p>
                  <p>Inasistencias</p>
                </div>
              </div>{" "}
            </Zoom>
            {/* Third Group*/}
          </div>
        </div>
        <div className="profile-img">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/logoLink.png?alt=media&token=27185fb4-1e29-4920-9fb1-ff09a85fe87c"
            alt="Logo CEHF"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
