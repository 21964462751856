import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Flash from "react-reveal/Flash";
import "./Article.css";

const Article = (props) => {
  const { userData } = props;
  // useParams
  const { id } = useParams();

  //useState
  const [article, setArticle] = useState({});
  // useEffect
  useEffect(() => {
    const getArticle = async () => {
      try {
        const data = await fetch(
          `https://cehf-app.herokuapp.com/${userData.idGrade}/${id}`
        );
        const res = await data.json();
        setArticle(res);
      } catch (error) {
        console.log(error);
      }
    };
    getArticle();
  }, [id, userData.idGrade]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {!article.materia ? (
            <div className="container-loading">
              <ReactLoading
                type="bars"
                color="#1a237e"
                height={100}
                width={100}
              ></ReactLoading>
            </div>
          ) : (
            <div>
              <Flash>
                <div className="article-background">
                  <img src={article.imagenUno} alt={article.materia} />
                </div>
              </Flash>
              <div className="article">
                <div className="article-title">
                  <h4>{article.materia}</h4>
                </div>
                <div className="article-contain">
                  {!article.imagenDos ? null : (
                    <div className="article-contain__img">
                      <img src={article.imagenDos} alt={article.materia} />
                    </div>
                  )}
                  <Flash>
                    
                  </Flash>
                  <div className="article-help">
                    <p>Material de Apoyo:</p>

                    {!article.linkUno ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkUno}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          1-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkDos ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkDos}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          2-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkTres ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkTres}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          3-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkCuatro ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkCuatro}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          4-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkCinco ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkCinco}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          5-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkSeis ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkSeis}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          6-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkSiete ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkSiete}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          7-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkOcho ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkOcho}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          8-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkNueve ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkNueve}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          9-. Link
                        </a>
                      </div>
                    )}
                    {!article.linkDiez ? null : (
                      <div className="material-apoyo__link">
                        <a
                          href={article.linkDiez}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          10-. Link
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <Flash>
                  <div className="article-footer">
                    <p>Envía: CEHF</p>
                    <div className="article-date">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/cehf-mx.appspot.com/o/appCehf%2FiconCalendario.svg?alt=media&token=edd47be8-cb82-4baf-b832-c234fa72b0bf"
                        alt="Icono Calendario"
                      />
                      <p>
                        Entregar antes de: <span>{article.entrega}</span>
                      </p>
                    </div>
                  </div>
                </Flash>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Article;
