import React from "react";
import ReactLoading from "react-loading";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading">
      <ReactLoading
        type="bubbles"
        color="#fff"
        height={100}
        width={100}
      ></ReactLoading>
    </div>
  );
};

export default Loading;
